import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './asi.css';
import '../components.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function ASI() {
    return (
        <section
            id="asi"
            className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container
                fluid
                className="logos"
                style={{ 'margin-top': '60px' }}>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/logos/dbvis-logo.png"
                            alt="DBVIS Logo"
                        />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/logos/unikn-logo.png"
                            alt="UNI KN Logo"
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            Introducing the Attribution Stability Indicator:{' '}
                            <br></br>a Measure for Time Series XAI Attributions
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Daniel A. Keim<sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col
                        className="text-align-center"
                        sm={8}>
                        <a href="https://lamarr-institute.github.io/xai-ts-workshop/program.html">
                            [ Explainable AI for Time Series (XAI-TS @ ECML-PKDD
                            2023), Turin, Italy ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/pdf/2310.04178.pdf">
                            [ Paper ]
                        </a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/abs/2310.04178">[ arXiv ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/attribution-stability-indicator">
                            [ Presentation ]
                        </a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/attribution-stability-indicator">
                            [ Code ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Evaluation of Attributions for Time Series</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Perturbation analysis evaluates the trustworthiness
                            of explainability methods by actively modifying
                            input data—such as{' '}
                            <span className="bold">
                                adding noise, removing segments, or altering
                                specific time points
                            </span>
                            —and observing the impact on{' '}
                            <span className="bold">model attributions</span>,
                            which explain how much each time point influences
                            the model’s predictions. This approach
                            systematically alters the data at different time
                            points, assessing shifts in{' '}
                            <span className="bold">
                                predictions and attributions
                            </span>{' '}
                            to identify the true impact of specific time points.{' '}
                            <span className="bold">
                                Significant variations in attributions with
                                small changes
                            </span>{' '}
                            suggest the model may rely on{' '}
                            <span className="bold">misleading time points</span>
                            , ensuring the{' '}
                            <span className="bold">
                                robustness and reliability of insights
                            </span>{' '}
                            in time series contexts. More information can be
                            found in [1].
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2020-empirical-study/experiment.png"
                            alt="Pertrubation analysis setup"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            The left part of the image shows a{' '}
                            <span className="bold">perturbation strategy</span>{' '}
                            where{' '}
                            <span className="bold">
                                specific time points are set to zero
                            </span>
                            , effectively neutralizing their influence to
                            observe the impact on{' '}
                            <span className="bold">model attributions</span>.
                            This approach tests the{' '}
                            <span className="bold">
                                importance of individual time points
                            </span>{' '}
                            by removing their effect. The right part of the
                            image illustrates a strategy where{' '}
                            <span className="bold">
                                a subsequence of time points is swapped
                            </span>
                            , altering the sequence order to evaluate the
                            model’s{' '}
                            <span className="bold">
                                understanding of temporal dependencies
                            </span>
                            . Both methods assess the{' '}
                            <span className="bold">
                                stability and reliability of model explanations
                            </span>{' '}
                            in time series analysis. More information can be
                            found in [2].
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2020-empirical-study/xai-verification.png"
                            alt="Perturbation strategies"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>
                            Motivation for an Attribution Evaluation Measure
                        </h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2023-asi/asi-motivation.png"
                            alt="Attribution Projection Visualization"
                        />
                    </Col>
                    <Col>
                        <p>
                            For a given dataset, we can calculate the
                            attributions for every sample and use a projection
                            technique to visualize the attributions of the whole
                            dataset. The image on the left presents the
                            attributions extracted with Integrated Gradients for
                            the training data of the FordA dataset applied to a
                            CNN using the projection technique UMAP. We have a{' '}
                            <span className="bold">
                                two-class problem and want suitable attributions
                            </span>{' '}
                            so that these attributions differ quite heavily for
                            each class and build two clusters to generate a
                            visual representation of the model. The{' '}
                            <span className="bold">
                                image on the left demonstrates such a scenario
                            </span>
                            . A working perturbation for{' '}
                            <span className="bold">
                                a suitable attribution then changes the
                                attribution of the perturbed instance toward the
                                other cluster, as in (A). Bad attribution
                                techniques create perturbed instances with
                                similar attributions, such as (B).
                            </span>{' '}
                            Thus, a promising measure must include the original
                            and the perturbed instance attribution similarity.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Different Requirements for Attributions</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            We define five critical requirements for attribution
                            techniques in time series XAI:
                        </p>
                        <ul>
                            <li>
                                <span>(R1)</span> <strong>Class Flip:</strong>{' '}
                                The attribution technique should detect changes
                                in the predicted class when the input time
                                series is perturbed.
                            </li>
                            <li>
                                <span>(R2)</span>{' '}
                                <strong>Prediction Probability Changes:</strong>{' '}
                                It should measure variations in prediction
                                probabilities resulting from input
                                perturbations.
                            </li>
                            <li>
                                <span>(R3)</span>{' '}
                                <strong>Attribution Distances:</strong> The
                                technique should assess the differences between
                                original and perturbed attributions.
                            </li>
                            <li>
                                <span>(R4)</span>{' '}
                                <strong>
                                    Time Series Perturbation Distances:
                                </strong>{' '}
                                It should quantify the extent of perturbations
                                applied to the original time series.
                            </li>
                            <li>
                                <span>(R5)</span>{' '}
                                <strong>User-Based Weighting:</strong> The
                                method should allow users to assign weights to
                                different factors based on their specific
                                requirements.
                            </li>
                        </ul>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Putting the ASI measure together</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2023-asi/asi-formulas.png"
                            alt="ASI measure forumlas"
                        />
                        <img
                            className="images-text"
                            src="images/2023-asi/asi-formulas.png"
                            alt="ASI measure forumlas"
                        />
                    </Col>
                    <Col>
                        <p>
                            <ul>
                                <li>
                                    <strong>1. Class Flip:</strong>
                                    <br></br>
                                    The ASI detects whether perturbations cause
                                    a change in the predicted class. This
                                    ensures that attributions remain meaningful
                                    even when the model's decision shifts.
                                </li>

                                <li>
                                    <strong>
                                        2. Prediction Probability Changes:
                                    </strong>
                                    <br></br>
                                    ASI measures variations in prediction
                                    probabilities using the Jensen-Shannon
                                    divergence, ensuring that changes in
                                    probability distributions are properly
                                    quantified.
                                </li>

                                <li>
                                    <strong>3. Attribution Distances:</strong>
                                    <br></br>
                                    The ASI evaluates differences between
                                    original and perturbed attributions using
                                    Pearson correlation and cosine similarity,
                                    ensuring that changes in attributions are
                                    appropriately captured.
                                </li>

                                <li>
                                    <strong>
                                        4. Time Series Perturbation Distances:
                                    </strong>
                                    <br></br>
                                    It quantifies how much the input time series
                                    was perturbed using similarity measures such
                                    as Dynamic Time Warping (DTW) or Euclidean
                                    distance.
                                </li>

                                <li>
                                    <strong>5. User-Based Weighting:</strong>
                                    <br></br>
                                    ASI provides a customizable weighting system
                                    that allows users to prioritize different
                                    factors based on their specific needs,
                                    making the stability evaluation more
                                    flexible.
                                </li>
                            </ul>
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>
                            Experiment results for a few attribution technqiues
                        </h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>

                    <Col>
                        <p>
                            The image on the right shows{' '}
                            <span className="bold">
                                on the top row the ASI score distributions for
                                every sample for our CNN model on the FordA
                                train dataset
                            </span>{' '}
                            using Saliency, GradientSHAP, DeepLIFT,
                            DeepLIFTSHAP, and Integrated Gradients binned into a
                            histogram.{' '}
                            <span className="bold">
                                The more Gaussian the ASI distribution looks,
                                the more stable the attributions are.
                            </span>{' '}
                            A Gaussian-like distribution suggests that the
                            attributions are less sensitive to minor changes in
                            the data and therefore more reliable.
                        </p>
                        <p>
                            <span className="bold">
                                On the bottom row, the projections of the
                                attributions of every sample using UMAP
                            </span>{' '}
                            with the class ground truth as coloring.{' '}
                            <span className="bold">
                                The farther the classes are separated in the
                                projections, the better the model and
                                attribution methods perform
                            </span>
                            , as this indicates that the attributions
                            effectively discriminate between the different
                            classes in the dataset.{' '}
                            <span className="bold">
                                The ideal projections show distinct,
                                well-separated clusters, reflecting that the
                                attribution methods provide meaningful and
                                accurate explanations
                            </span>{' '}
                            for the CNN model's predictions.
                        </p>
                        <p>
                            <span className="bold">
                                The more Gaussian the ASI distribution looks on
                                the top for the corresponding classes, and the
                                farther the classes are separated, the better
                                the projections are.
                            </span>
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2023-asi/asi-scores.png"
                            alt="ASI scores for different attribution techniques for a CNN trained on FordA data"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] Schlegel, U., Arnout, H., El-Assady, M., Oelke,
                            D., & Keim, D. A. (2019). Towards a rigorous
                            evaluation of XAI methods on time series. In 2019
                            IEEE/CVF International Conference on Computer Vision
                            Workshop (ICCVW) (pp. 4197-4201). IEEE.
                        </p>
                        <p className="references">
                            [2] Schlegel, U., Oelke, D., Keim, D. A., &
                            El-Assady, M. (2020). An empirical study of
                            explainable AI techniques on deep learning models
                            for time series tasks. arXiv preprint
                            arXiv:2012.04344.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}
