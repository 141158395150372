import { Main } from './components/main';

/* Background */

import { Review } from './components/2022-time-series-xai-review/review';
import { VisForAtt } from './components/2021-attribution-visualizations-as-explanations/vis-for-att';

/* Generation of Time Series Explanations */

import { TSMule } from './components/2021-ts-mule/ts-mule';
import { ICFTS } from './components/2024-icfts/icfts';

/* Evaluation of Time Series Explanations */

import { PreStudy } from './components/2019-preliminary-study/pre-study';
import { Study } from './components/2020-empirical-study/study';
import { DeepDive } from './components/2023-deep-dive/deep';
import { ASI } from './components/2023-asi/asi';

/* Interactive Visual Time Series Explanations */

import { DAVOTS } from './components/2025-davots/davots';
import { VisualExplanations } from './components/2024-veac/visual';

/* Interactive Visual time Series Explanations */

import { Switch, Route } from 'react-router-dom';

// import $ from 'jquery';

import 'font-awesome/css/font-awesome.min.css';
import 'font-awesome/fonts/fontawesome-webfont.ttf';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
    return (
        <div className="App">
            <Switch>
                <Route
                    exact
                    path="/"
                    component={Main}></Route>

                {/* Background */}

                <Route
                    path="/explainer"
                    component={() => {
                        window.location.href = 'https://explainer.ai/';
                        return null;
                    }}
                />

                <Route
                    exact
                    path="/xai-tsc-survey"
                    component={Review}></Route>

                <Route
                    exact
                    path="/vis-for-att-survey"
                    component={VisForAtt}></Route>

                {/* Generation of Time Series Explanations */}

                <Route
                    exact
                    path="/ts-mule"
                    component={TSMule}></Route>

                <Route
                    exact
                    path="/icfts"
                    component={ICFTS}></Route>

                {/* Evaluation of Time Series Explanations */}

                <Route
                    exact
                    path="/pre-study"
                    component={PreStudy}></Route>

                <Route
                    exact
                    path="/empirical-study"
                    component={Study}></Route>

                <Route
                    exact
                    path="/deep-dive"
                    component={DeepDive}></Route>

                <Route
                    exact
                    path="/asi"
                    component={ASI}></Route>

                {/* Interactive Visual time Series Explanations */}

                <Route
                    exact
                    path="/debugging"
                    component={PreStudy}></Route>

                <Route
                    exact
                    path="/davots"
                    component={DAVOTS}></Route>

                <Route
                    exact
                    path="/vaec"
                    component={VisualExplanations}></Route>
            </Switch>
        </div>
    );
}

export default App;
