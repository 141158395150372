import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './icfts.css';
import '../components.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function ICFTS() {
    return (
        <section id="icfts" className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container fluid className="logos" style={{ marginTop: '60px' }}>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/dbvis-logo.png" alt="DBVIS Logo" />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img className="logo" src="images/logos/unikn-logo.png" alt="UNI KN Logo" />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            Interactive Counterfactual Generation for<br></br> Univariate Time
                            Series
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Julius Rauscher<sup>1</sup>, Daniel A. Keim
                            <sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col className="text-align-center" sm={10}>
                        <a href="http://xkdd2024.isti.cnr.it/">
                            [ eXplainable Knowledge Discovery in Data Mining (XKDD @ ECML-PKDD
                            2024), Vilnius, Lithuania ]
                        </a>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/pdf/2408.10633.pdf">[ Paper ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/abs/2408.10633">[ arXiv ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="data/XKDD-2024-Presentation.pdf">[ Presentation ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://icfts.time-series-xai.dbvis.de/">[ Demo ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/interactive-counterfactuals-for-time-series">
                            [ Code ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            <span className="bold">Counterfactual generation</span> can be achieved
                            through various approaches, such as{' '}
                            <span className="bold">optimization-based</span> methods when the model
                            is differentiable, utilizing a loss function related to the desired
                            counterfactual, <span className="bold">evolutionary methods</span> using
                            techniques similar to genetic algorithms,{' '}
                            <span className="bold">heuristic-based methods</span> by altering
                            segments of the time series with those from other classes, among others.
                            However, these counterfactuals can sometimes{' '}
                            <span className="bold">lack plausibility</span> and fail to provide
                            meaningful insights into the data distribution, as they may be{' '}
                            <span className="bold">out of distribution</span>.
                        </p>
                        <p>
                            To address this issue, we propose a{' '}
                            <span className="bold">
                                projection and inverse projection application
                            </span>{' '}
                            (using UMAP [1]) that projects time series samples into a{' '}
                            <span className="bold">2D space</span>, enabling visualization with{' '}
                            <span className="bold">decision boundaries</span> in the background,
                            thereby creating a <span className="bold">decision boundary map</span>.
                            For counterfactual generation, we employ{' '}
                            <span className="bold">inverse projection</span> on a selected or
                            manipulated point position to generate a new sample and determine if it
                            qualifies as a counterfactual. If the generated sample does not meet
                            this criterion, the user can interactively{' '}
                            <span className="bold">drag the point further</span> or select another
                            point. Additionally, we extend this capability to allow users to perform
                            these actions not only in the{' '}
                            <span className="bold">time series space</span> but also in the model's{' '}
                            <span className="bold">activation space</span> and the{' '}
                            <span className="bold">attribution space</span> of an applied
                            attribution technique.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2024-icfts/icfts-overview.png"
                            alt="Overview"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Generating time series samples from inverse projections</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2024-icfts/icfts-generation.png"
                            alt="Counterfactual Generation"
                        />
                    </Col>
                    <Col>
                        <p>
                            Clicking on the <span className="bold">decision boundary map</span>{' '}
                            generates a <span className="bold">new sample</span> using an{' '}
                            <span className="bold">optimization-based approach</span>. First, the{' '}
                            <span className="bold">inverse projection</span> of the selected point
                            in the <span className="bold">2D activation or attribution space</span>{' '}
                            is computed in the{' '}
                            <span className="bold">higher-dimensional space</span>, creating{' '}
                            <span className="bold">new activations or attributions</span> for a
                            sample. This <span className="bold">generated data</span> serves as the{' '}
                            <span className="bold">target for optimization</span>.
                        </p>
                        <p>
                            We then <span className="bold">iteratively refine</span> the{' '}
                            <span className="bold">input time series sample</span> by repeatedly
                            adjusting it to reduce the difference between its current activations or
                            attributions and the desired ones. This optimization loop continues
                            until the input sample matches the{' '}
                            <span className="bold">desired activation or attribution</span> closely
                            enough, ensuring that the final output aligns with the targeted
                            characteristics.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Examples in attribution and activation space</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            On the right is an example where a point representing a{' '}
                            <span className="bold">time series sample</span> in the{' '}
                            <span className="bold">attribution space</span> (shown in the left image
                            on the right) is gradually dragged toward the{' '}
                            <span className="bold">decision boundary</span> in the background
                            (moving from orange to green). At the bottom, a line plot shows the{' '}
                            <span className="bold">time series</span>, along with several similar
                            lines, while the <span className="bold">counterfactual</span> appears as
                            a distinct line that retains some similar features but differs overall.
                            Notably, the <span className="bold">projected time series</span> and{' '}
                            <span className="bold">activations view</span> on the right highlight a
                            significant shift of the counterfactual from one{' '}
                            <span className="bold">cluster</span> to another.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2024-icfts/icfts-attributions.png"
                            alt="Generate counterfactual based on attribtuion"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center" sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/2024-icfts/icfts-activations.png"
                            alt="Generate counterfactual based on activations"
                        />
                    </Col>
                    <Col>
                        <p>
                            This example demonstrates the generation of{' '}
                            <span className="bold">counterfactual time series samples</span> using{' '}
                            <span className="bold">projected activations</span>. By gradually
                            dragging a selected point from one <span className="bold">cluster</span>{' '}
                            to another, based on the{' '}
                            <span className="bold">projected activation decision boundary map</span>
                            , we observe various{' '}
                            <span className="bold">generated time series samples</span> in the{' '}
                            <span className="bold">line plot</span> at the bottom. Many of these
                            generated samples share <span className="bold">common features</span>{' '}
                            with the original time series but also exhibit some{' '}
                            <span className="bold">distinct or altered characteristics</span>. The{' '}
                            <span className="bold">counterfactual samples</span>, while retaining
                            certain features of the original, display{' '}
                            <span className="bold">unique time series characteristics</span> that
                            differentiate them from the rest.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] McInnes, L., Healy, J., & Melville, J. (2018). Umap: Uniform
                            manifold approximation and projection for dimension reduction. arXiv
                            preprint arXiv:1802.03426.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}

