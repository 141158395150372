import { React, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './deep.css';
import '../components.css';

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export function DeepDive() {
    return (
        <section
            id="deep"
            className="components">
            <ScrollToTopOnMount />

            <div className="return">
                <Link to="/">[ Return ]</Link>
            </div>

            <Container
                fluid
                className="logos"
                style={{ 'margin-top': '60px' }}>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/logos/dbvis-logo.png"
                            alt="DBVIS Logo"
                        />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/logos/unikn-logo.png"
                            alt="UNI KN Logo"
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            A Deep Dive into Perturbations as Evaluation
                            Technique <br></br>for Time Series XAI
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Daniel A. Keim<sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col
                        className="text-align-center"
                        sm={8}>
                        <a href="https://xaiworldconference.com/2023/full-programme/">
                            [ World Conference on eXplainable Artificial
                            Intelligence (xAI 2023), Lisbon, Portugal ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/pdf/2307.05104.pdf">
                            [ Paper ]
                        </a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://arxiv.org/abs/2307.05104">[ arXiv ]</a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/time-series-xai-perturbation-analysis">
                            [ Presentation ]
                        </a>
                    </Col>
                    <Col className="text-align-center">
                        <a href="https://github.com/visual-xai-for-time-series/time-series-xai-perturbation-analysis">
                            [ Code ]
                        </a>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>
                            Perturbation analysis for time series deep learning
                            models
                        </h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Perturbation analysis evaluates the trustworthiness
                            of explainability methods by actively modifying
                            input data—such as{' '}
                            <span className="bold">
                                adding noise, removing segments, or altering
                                specific time points
                            </span>
                            —and observing the impact on{' '}
                            <span className="bold">model attributions</span>,
                            which explain how much each time point influences
                            the model’s predictions. This approach
                            systematically alters the data at different time
                            points, assessing shifts in{' '}
                            <span className="bold">
                                predictions and attributions
                            </span>{' '}
                            to identify the true impact of specific time points.{' '}
                            <span className="bold">
                                Significant variations in attributions with
                                small changes
                            </span>{' '}
                            suggest the model may rely on{' '}
                            <span className="bold">misleading time points</span>
                            , ensuring the{' '}
                            <span className="bold">
                                robustness and reliability of insights
                            </span>{' '}
                            in time series contexts. More information can be
                            found in [1].
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2020-empirical-study/experiment.png"
                            alt="Pertrubation analysis setup"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            The left part of the image shows a{' '}
                            <span className="bold">perturbation strategy</span>{' '}
                            where{' '}
                            <span className="bold">
                                specific time points are set to zero
                            </span>
                            , effectively neutralizing their influence to
                            observe the impact on{' '}
                            <span className="bold">model attributions</span>.
                            This approach tests the{' '}
                            <span className="bold">
                                importance of individual time points
                            </span>{' '}
                            by removing their effect. The right part of the
                            image illustrates a strategy where{' '}
                            <span className="bold">
                                a subsequence of time points is swapped
                            </span>
                            , altering the sequence order to evaluate the
                            model’s{' '}
                            <span className="bold">
                                understanding of temporal dependencies
                            </span>
                            . Both methods assess the{' '}
                            <span className="bold">
                                stability and reliability of model explanations
                            </span>{' '}
                            in time series analysis. More information can be
                            found in [2].
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2020-empirical-study/xai-verification.png"
                            alt="Perturbation strategies"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Perturbation strategies for time series data</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <table
                            style={{
                                width: '100%',
                                height: '100%',
                                borderCollapse: 'collapse',
                            }}
                            border="1">
                            <tr>
                                <th>FordA / 3601</th>
                                <th>Saliency</th>
                                <th>IntegratedGradients</th>
                                <th>DeepLIFT</th>
                                <th>Occlusion</th>
                                <th>GradientSHAP</th>
                                <th>DeepLIFTSHAP</th>
                                <th>KernelSHAP</th>
                            </tr>
                            <tr>
                                <td>Zero</td>
                                <td>1744</td>
                                <td>1746</td>
                                <td>1743</td>
                                <td>2029</td>
                                <td>1746</td>
                                <td>1744</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Inverse</td>
                                <td>1744</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1639</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Local Mean</td>
                                <td>1744</td>
                                <td>1746</td>
                                <td>1743</td>
                                <td>2029</td>
                                <td>1746</td>
                                <td>1744</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Global Mean</td>
                                <td>1744</td>
                                <td>1746</td>
                                <td>1743</td>
                                <td>2028</td>
                                <td>1746</td>
                                <td>1744</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Global Max</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1624</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Global Min</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1594</td>
                                <td>1744</td>
                                <td>1743</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>OOD Low</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1636</td>
                                <td>1743</td>
                                <td>1744</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>OOD High</td>
                                <td>1744</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1606</td>
                                <td>1743</td>
                                <td>1743</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Random</td>
                                <td>1744</td>
                                <td>1744</td>
                                <td>1743</td>
                                <td>1641</td>
                                <td>1743</td>
                                <td>1744</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Zero Sub</td>
                                <td>1743</td>
                                <td>1789</td>
                                <td>1756</td>
                                <td>1807</td>
                                <td>1749</td>
                                <td>1754</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Inverse Sub</td>
                                <td>1823</td>
                                <td>1745</td>
                                <td>1748</td>
                                <td>1628</td>
                                <td>1749</td>
                                <td>1752</td>
                                <td>1852</td>
                            </tr>
                            <tr>
                                <td>Local Mean Sub</td>
                                <td>1743</td>
                                <td>1789</td>
                                <td>1756</td>
                                <td>1807</td>
                                <td>1749</td>
                                <td>1754</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>Global Mean Sub</td>
                                <td>1743</td>
                                <td>1789</td>
                                <td>1756</td>
                                <td>1810</td>
                                <td>1748</td>
                                <td>1754</td>
                                <td>1743</td>
                            </tr>
                            <tr>
                                <td>OOD Low Sub</td>
                                <td>2088</td>
                                <td>1768</td>
                                <td>1804</td>
                                <td>1639</td>
                                <td>1876</td>
                                <td>1870</td>
                                <td>2049</td>
                            </tr>
                            <tr>
                                <td>OOD High Sub</td>
                                <td>1997</td>
                                <td>1760</td>
                                <td>1776</td>
                                <td>1445</td>
                                <td>1835</td>
                                <td>1815</td>
                                <td>1985</td>
                            </tr>
                            <tr>
                                <td>Random Sub</td>
                                <td>1796</td>
                                <td>1787</td>
                                <td>1774</td>
                                <td>1625</td>
                                <td>1780</td>
                                <td>1793</td>
                                <td>1771</td>
                            </tr>
                        </table>
                    </Col>
                    <Col>
                        <p>
                            <span className="bold">
                                Perturbation strategies
                            </span>{' '}
                            in time series analysis involve{' '}
                            <span className="bold">
                                altering specific time points or subsequences
                            </span>{' '}
                            to assess the{' '}
                            <span className="bold">
                                robustness of model explanations
                            </span>
                            . Techniques like <span className="bold">Zero</span>{' '}
                            set time points to zero, while{' '}
                            <span className="bold">Inverse</span> sets them to
                            their inverse values. Strategies such as{' '}
                            <span className="bold">Local Mean</span> and{' '}
                            <span className="bold">Global Mean</span> replace
                            time points with mean values from the sample or
                            dataset, respectively. Extremes like{' '}
                            <span className="bold">Global Max</span> and{' '}
                            <span className="bold">Global Min</span> set points
                            to dataset boundaries, while{' '}
                            <span className="bold">OOD Low</span> and{' '}
                            <span className="bold">OOD High</span> introduce
                            out-of-distribution values. Similar operations apply
                            to subsequences, such as setting entire segments to
                            zero (<span className="bold">Zero Sub</span>) or
                            replacing them with random values (
                            <span className="bold">Random Sub</span>), enabling
                            a comprehensive evaluation of{' '}
                            <span className="bold">model stability</span> across
                            different perturbations.
                        </p>
                        <p>
                            The left table shows the{' '}
                            <span className="bold">class switches</span> based
                            on the{' '}
                            <span className="bold">perturbation strategy</span>{' '}
                            and{' '}
                            <span className="bold">attribution technique</span>{' '}
                            applied to the FordA training data. A{' '}
                            <span className="bold">
                                large number of switches
                            </span>{' '}
                            indicate that the combination of the attribution
                            technique and perturbation strategy leads to{' '}
                            <span className="bold">high trustworthiness</span>,
                            demonstrating that the model's explanations remain{' '}
                            <span className="bold">stable and reliable</span>{' '}
                            even under varied data manipulations.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>
                            The perturbation analysis card as information
                            summarization
                        </h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            The{' '}
                            <span className="bold">
                                perturbation analysis card
                            </span>{' '}
                            visually summarizes how different{' '}
                            <span className="bold">
                                perturbation strategies
                            </span>{' '}
                            affect time series data and{' '}
                            <span className="bold">model attributions</span>. It
                            includes various metrics, such as{' '}
                            <span className="bold">label changes</span> to show
                            classification impacts,{' '}
                            <span className="bold">histograms</span>{' '}
                            illustrating the amount and distribution of
                            perturbed values, and{' '}
                            <span className="bold">
                                distance metrics (Euclidean and cosine)
                            </span>{' '}
                            to indicate how far perturbed instances deviate from
                            the original data. The{' '}
                            <span className="bold">
                                skewness and mean of attributions
                            </span>{' '}
                            highlight shifts in model explanations, while the{' '}
                            <span className="bold">
                                line plot compares the data samples' means
                            </span>
                            , showing how perturbations alter the overall time
                            series patterns. This comprehensive view assesses
                            the{' '}
                            <span className="bold">
                                stability and reliability of model explanations
                            </span>{' '}
                            under different perturbations.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2023-deep-dive/deep-forda-card.png"
                            alt="FordA Perturbation Analysis Card"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>The experiment setup and results</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2023-deep-dive/deep-skewness.png"
                            alt="FordA skewness of attributions"
                        />
                    </Col>
                    <Col>
                        <p>
                            The left image presents the{' '}
                            <span className="bold">
                                skewness of attributions
                            </span>{' '}
                            for various{' '}
                            <span className="bold">attribution techniques</span>{' '}
                            applied to a CNN trained on the FordA dataset. The
                            colors indicate whether the{' '}
                            <span className="bold">prediction changed</span>{' '}
                            after applying the{' '}
                            <span className="bold">
                                zero perturbation strategy
                            </span>
                            . Particularly interesting are the different{' '}
                            <span className="bold">skewness distributions</span>
                            : Saliency, Occlusion, and KernelSHAP are
                            concentrated around specific skewness values, while
                            others exhibit a{' '}
                            <span className="bold">
                                Gaussian-like distribution
                            </span>
                            . It is noteworthy that{' '}
                            <span className="bold">
                                changed and unchanged predictions
                            </span>{' '}
                            follow different distributions, overlapping only in
                            certain regions, which highlights the{' '}
                            <span className="bold">
                                distinction in behavior
                            </span>{' '}
                            between these categories.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Looking at the FordB training data and a{' '}
                            <span className="bold">
                                perturbation analysis card
                            </span>
                            , we can identify when the{' '}
                            <span className="bold">prediction flips</span>. For
                            example, in the card on the right, most changes
                            occur when{' '}
                            <span className="bold">
                                nearly the entire time series is set to zero
                            </span>
                            , altering the prediction. This outcome is{' '}
                            <span className="bold">undesirable</span>, so
                            additional metrics are needed to{' '}
                            <span className="bold">
                                compare attribution techniques
                            </span>
                            . The{' '}
                            <span className="bold">
                                perturbation-to-zero strategy
                            </span>{' '}
                            with <span className="bold">KernelSHAP</span>{' '}
                            produces the best results, suggesting the method is{' '}
                            <span className="bold">trustworthy</span>, but only
                            after perturbing nearly the entire original data.
                            This can <span className="bold">mislead users</span>{' '}
                            into thinking KernelSHAP outperforms other methods,
                            which is not ideal.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="/images/2023-deep-dive/deep-fordb-card.png"
                            alt="FordB Perturbation Analysis Card"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] Schlegel, U., Arnout, H., El-Assady, M., Oelke,
                            D., & Keim, D. A. (2019). Towards a rigorous
                            evaluation of XAI methods on time series. In 2019
                            IEEE/CVF International Conference on Computer Vision
                            Workshop (ICCVW) (pp. 4197-4201). IEEE.
                        </p>
                        <p className="references">
                            [2] Schlegel, U., Oelke, D., Keim, D. A., &
                            El-Assady, M. (2020). An empirical study of
                            explainable AI techniques on deep learning models
                            for time series tasks. arXiv preprint
                            arXiv:2012.04344.
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}
