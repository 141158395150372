import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './davots.css';

export function DAVOTS() {
    return (
        <section
            id="visual-demo"
            className="components">
            <Container
                fluid
                className="logos">
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/empirical-study/dbvis-logo.png"
                            alt="DBVIS Logo"
                        />
                    </Col>
                    <Col></Col>
                    <Col sm={2}>
                        <img
                            className="logo"
                            src="images/empirical-study/unikn-logo.png"
                            alt="UNI KN Logo"
                        />
                        <img
                            className="logo"
                            src="images/empirical-study/hsof-logo.png"
                            alt="HS Offenburg Logo"
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h1>
                            Interactive Dense Pixel Visualizations for Time
                            Series and Model Attribution Explanations
                        </h1>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <h3>
                            Udo Schlegel<sup>1</sup>, Daniel A. Keim<sup>1</sup>
                        </h3>
                        <h3>
                            <sup>1</sup> University of Konstanz
                        </h3>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            <span className="bold">Explainable AI (XAI)</span>{' '}
                            bridges the gap between state-of-the-art deep
                            learning and production-ready models. Deep neural
                            networks{' '}
                            <span className="bold">
                                lack explainable decision making
                            </span>{' '}
                            and are often not suitable for production as
                            decisions{' '}
                            <span className="bold">cannot be trusted</span> and
                            are{' '}
                            <span className="bold">challenging to debug</span>.
                            XAI techniques are developed to interpret such model
                            decisions and to{' '}
                            <span className="bold">
                                generate comprehensible explanations.
                            </span>
                        </p>
                        <p>
                            Most XAI techniques aiding in understanding and
                            debugging are{' '}
                            <span className="bold">
                                developed for images or text.
                            </span>{' '}
                            Most prominent techniques{' '}
                            <span className="bold">generate attributions</span>{' '}
                            on images as heatmaps to highlight relevant pixels.{' '}
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/empirical-study/xai-images.png"
                            alt="XAI for Images"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>XAI: From Images to Time Series</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Sensors{' '}
                            <span className="bold">
                                generate massive amounts of time series
                            </span>{' '}
                            with a variety of tasks for machine learning models.
                            Nowadays, more and more deep neural networks are
                            trained on tasks such as{' '}
                            <span className="bold">anomaly detection</span>.
                            Attributions for such models on time series lead to{' '}
                            <span className="bold">
                                incomprehensible and unverified explanations.
                            </span>
                        </p>
                        <p>
                            Such attributions are often only evaluated by a
                            qualitative approach{' '}
                            <span className="bold">
                                inspecting a few samples
                            </span>
                            . However, such a sample-based, human evaluation is{' '}
                            <span className="bold">
                                not enough to identify effective XAI techniques
                            </span>
                            ; automated quantitive assessment and verification
                            benchmarks are needed to find such techniques.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/empirical-study/xai-time-series.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Design Space of the Experiment</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/empirical-study/design-space.png"
                            alt="Design Space"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Our design space of the experiment includes at first{' '}
                            <span className="bold">
                                three time series tasks
                            </span>{' '}
                            (regression, forecasting, and classification) with
                            various datasets ranging from stock market
                            prediction to weather forecasting to anomaly
                            detection. We further include{' '}
                            <span className="bold">
                                seven state-of-the-art deep learning
                                architectures
                            </span>{' '}
                            in their default configuration for these tasks. As
                            our last dimension, we incorporate the{' '}
                            <span className="bold">
                                most recent and prominent fifteen XAI
                                techniques.
                            </span>
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Hypotheses and Research Questions</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <p className="bold">
                            Transferring attribution methods to time series:
                        </p>
                        <ul>
                            <li>
                                For each of the considered XAI attribution
                                methods, can we transfer them to time series?
                            </li>
                            <li>
                                What are the tweaks and changes needed to apply
                                each method?
                            </li>
                            <li>
                                Are some methods better suited for particular
                                models and tasks?
                            </li>
                        </ul>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <p className="bold">
                            Evaluating the applicability of transferred methods
                            to time series tasks:
                        </p>
                        <ul>
                            <li>
                                Which measures of validation are required for a
                                systematic evaluation of each method on the
                                given tasks?
                            </li>
                            <li>
                                How strong does an XAI attribution reflect the
                                model's predictions?
                            </li>
                            <li>
                                Can we rank and identify strengths as well as
                                weaknesses of attributions on time series for
                                given tasks?
                            </li>
                        </ul>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={2}></Col>
                    <Col>
                        <p className="bold">
                            Benchmarking attribution methods on time series
                            tasks:
                        </p>
                        <ul>
                            <li>
                                Given a concrete model architecture, dataset,
                                and task; how sensitive are XAI methods?
                            </li>
                            <li>
                                What is the overall strongest performing XAI
                                method for each task?
                            </li>
                            <li>
                                Can we reproduce the results of Schlegel et al.
                                [3] showing SHAP as best performing?
                            </li>
                        </ul>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Experiment Setup</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Perturbations as Verification</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Perturbations of time series{' '}
                            <span className="bold">
                                based on XAI technique explanations
                            </span>{' '}
                            can be applied to various test data. With such a
                            modified version,{' '}
                            <span className="bold">
                                measuring the change in a quality metric
                            </span>{' '}
                            for the underlying model is possible. However, there
                            are multiple methods to perturb time series. We
                            focus on and{' '}
                            <span className="bold">
                                extend our previous work [3]
                            </span>{' '}
                            by adding more sophisticated perturbations.{' '}
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/empirical-study/xai-verification.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <h2>Evaluation and Verification</h2>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row
                    className="align-items-center"
                    sm={12}>
                    <Col sm={1}></Col>
                    <Col>
                        <p>
                            Stages: model training, model explanation, and
                            explanation evaluation to calculate a score for each
                            XAI technique.
                            <br />
                            <span className="bold">Model training:</span> train
                            deep learning architecture on a fixed dataset and
                            predict test data.
                            <br />
                            <span className="bold">
                                Model explanation:
                            </span>{' '}
                            <span className="italic">1.</span> Apply available
                            XAI techniques on the trained model to generate
                            explanations. <span className="italic">2.</span>{' '}
                            Modify test data based on the explanations and use
                            the modified test data for another prediction.{' '}
                            <span className="italic">3.</span> Create random
                            explanations and do the same.
                            <br />
                            <span className="bold">
                                Explanation evaluation:
                            </span>{' '}
                            compare the prediction of the test data to the
                            predictions of the modified test data. If the
                            explanation is sufficient, the second prediction is
                            worse than the first and the random prediction.
                        </p>
                    </Col>
                    <Col>
                        <img
                            className="images-text"
                            src="images/empirical-study/experiment.png"
                            alt="XAI for Time Series"
                        />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
                <Row className="divider"></Row>
                <Row className="align-items-center">
                    <Col sm={1}></Col>
                    <Col>
                        <p className="references">
                            [1] Simonyan, K., Vedaldi, A., & Zisserman, A.
                            (2013). Deep Inside Convolutional Networks:
                            Visualising Image Classification Models and Saliency
                            Maps. arXiv preprint arXiv:1312.6034
                        </p>
                        <p className="references">
                            [2] Bach, S., Binder, A., Montavon, G., Klauschen,
                            F., Müller, K.-R., & Samek, W. (2015). On Pixel-Wise
                            Explanations for Non-Linear Classifier Decisions by
                            Layer-Wise Relevance Propagation. PLOS ONE, 10(7)
                        </p>
                        <p className="references">
                            [3] Schlegel, U., Arnout, H., El-Assady, M., Oelke,
                            D., & Keim, D. A. (2019). Towards a rigorous
                            evaluation of XAI Methods on Time Series. arXiv
                            preprint arXiv:1909.07082
                        </p>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </section>
    );
}
